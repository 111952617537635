var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "new-object-dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.header,
            visible: _vm.showDialog,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            "before-close": _vm.closeDialog,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "objectForm",
              attrs: { model: _vm.form, rules: _vm.rules },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createObject.apply(null, arguments)
                },
              },
            },
            [
              _vm.typeProp === "folder"
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "folder_name" } },
                    [
                      _c("el-input", {
                        ref: "folderName",
                        attrs: {
                          autocomplete: "off",
                          pattern: "^[0-9a-zA-Z_\\-. ]+$",
                          placeholder: _vm.$t("fileList.name"),
                        },
                        model: {
                          value: _vm.form.folder_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "folder_name", $$v)
                          },
                          expression: "form.folder_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.typeProp === "file"
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "fileList", required: "" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload-demo",
                          attrs: {
                            "file-list": _vm.fileList,
                            action: "",
                            "on-remove": _vm.resetFileContentAndClearFiles,
                            "on-change": _vm.processAfterSelectsFile,
                            multiple: false,
                            "auto-upload": false,
                            drag: "",
                          },
                          model: {
                            value: _vm.form.fileList,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fileList", $$v)
                            },
                            expression: "form.fileList",
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("uploadPage.dropFileHere")) +
                                " "
                            ),
                            _c("em", [
                              _vm._v(
                                _vm._s(_vm.$t("uploadPage.clickToSelect"))
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("uploadPage.upload_limit", {
                                      size: _vm.maxSize,
                                    })
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.validationMessages.length
            ? _c("p", [
                _c(
                  "ul",
                  { staticClass: "validation-errors" },
                  _vm._l(_vm.validationMessages, function (error, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(error) + " "),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("labels.cancel")))]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.loading,
                      expression: "loading",
                      modifiers: { fullscreen: true, lock: true },
                    },
                  ],
                  attrs: { disabled: !_vm.validateForm(), type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.createObject()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("labels.create")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }