/* tslint:disable */
import './404'
import './add_circle'
import './back-top'
import './bug'
import './chart'
import './clipboard'
import './cloud_upload'
import './cloud'
import './component'
import './create_folder'
import './dashboard'
import './delete'
import './documentation'
import './drag'
import './edit'
import './education'
import './email'
import './example'
import './excel'
import './exit-fullscreen'
import './eye-off'
import './eye-on'
import './fit_content'
import './fit_image'
import './fit_width'
import './form'
import './fullscreen'
import './guide-2'
import './guide'
import './hamburger'
import './icon'
import './international'
import './language'
import './like'
import './link'
import './list'
import './lock'
import './logout'
import './message'
import './money'
import './nested'
import './password'
import './pdf'
import './people'
import './peoples'
import './qq'
import './rotate_left'
import './rotate_right'
import './search'
import './share'
import './shopping'
import './show_real_size'
import './size'
import './skill'
import './star'
import './tab'
import './table'
import './theme'
import './tree-table'
import './tree'
import './upload-completed'
import './upload-failed'
import './upload'
import './user'
import './wechat'
import './zip'
