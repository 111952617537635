export default {
  route: {
    trash: 'Skräp',
    upload: 'Ladda upp',
    browse: 'Bläddra',
    shared: 'Delad',
    new: 'Ny',
    create_folder: 'Skapa mapp',
    upload_file: 'Ladda upp fil',
    home: 'MyLibrary',
    logout: 'Logga ut',
  },
  logout: {
    title: 'Logga ut',
    message: 'Är du säker på att du vill logga ut?Detta kommer också att logga ut dig från de andra associerade webbplatserna och omdirigera dig till inloggningssidan efteråt.'
  },
  preview: {
    title: 'Förhandsvisning',
    notAvailable: 'Förhandsgranskning är inte tillgänglig',
    toolbar: {
      rotateLeft: 'Rotera vänster',
      rotateRight: 'Vrid höger',
      zoomIn: 'Zooma in',
      zoomOut: 'Zooma ut',
      toggleFitWidth: 'Växla fit bredd',
      fitImage: 'Passa bild',
      actualSize: 'Faktiska storleken'
    },
    previewError: {
      unSupported: 'Det går inte att öppna den här filen eftersom den är i ett icke -stödt format'
    },
  },
  fileList: {
    name: 'Namn',
    shareColumn: 'Dela med sig',
    last_modified: 'Senast ändrad',
    file_size: 'Filstorlek',
    folder_size: 'Mappstorlek',
    deleted_at: 'Borttagen kl',
    shared_at: 'Delad kl',
    shared_by: 'Delad av',
    onlyMe: 'Bara jag',
    sharePopover: {
      shared_at: 'Delad kl',
      shared_to: 'Delad till',
      opener: 'Delad',
      action: 'Action',
      sharedWith: 'Delad med',
      notfound: 'The shared list is empty',
      edit: 'Edit',
    },
    created_by: 'Skapad Av',
    unavailable: 'Inte tillgänglig',
    source: 'Källa',
    action: 'Handling'
  },
  page: {
    SharePage: {
      heading: 'Delad lista',
      unlinkShareAction: {
        promptMessage: 'Är du säker på att du vill ta bort åtkomsten till {fileTypeAndName}?',
        promptTitle: 'Ta bort delat objekt',
        success: 'Avlägsnade framgångsrikt åtkomsten till {fileTypeAndName}',
        failed: 'Det går inte att ta bort åtkomsten. Försök efter någon gång',
      },
    },
    trashPage: {
      emptyTrash: {
        btnText: 'Tomt skräp',
        prompt: {
          btnYes: 'Ja, fortsätt',
          title: 'Tomt skräp',
          message: 'Är du säker på att du vill ta bort alla objekt i papperskorgen?Denna åtgärd kan inte ångra typen "{verification}" i textrutan för att fortsätta',
          verificationMisMatch: 'Den inmatade bekräftelsetexten matchar inte.Var god försök igen.',
        },
        response: {
          success: 'Alla filer i papperskorgen har tagits bort',
          error: 'Det går inte att rensa papperskorgen.Försök efter någon gång',
        }
      },
      permanentDelete: {
        actionButton: 'Permanent radering vald',
        prompt: {
          title: 'Permanent radering',
          messageSingle: 'Är du säker på att du vill ta bort denna {fileTypeAndName} permanent?Denna åtgärd kan inte ångra typen "{verification}" i textrutan för att fortsätta',
          messageMultiple: 'Är du säker på att du vill radera permanent de valda objekten?Denna åtgärd kan inte ångra typen "{verification}" i textrutan för att fortsätta',
          verificationMisMatch: 'Den inmatade bekräftelsetexten matchar inte.Var god försök igen.',
          btnYes: 'Ja, fortsätt',
        },
        response: {
          failedSingle: 'Det går inte att ta bort {fileTypeAndName} permanent.Försök efter någon gång',
          failedMultiple: 'Det går inte att ta bort de valda objekten permanent.Försök efter någon gång',
          successSingle: '{fileTypeAndName} har tagits bort permanent',
          successMultiple: 'De valda artiklarna har tagits bort permanent',
        }
      },
      restoreSelected: 'Återställa vald',
    },
    browsePage: {
      deleteSelected: 'Radera valda',
      deleteSelectedTitle: 'Ta bort valda objekt',
      deleteSelectedPrompt: 'Är du säker på att du vill ta bort valda objekt?',
      deleteSelectedSuccess: 'De valda objekten har tagits bort',
      deleteSelectedError: 'Det går inte att ta bort de valda objekten. Försök efter någon gång',
      moveSelected: 'Flytta vald',
    }
  },
  contextMenu: {
    download: 'Ladda ner',
    open: 'Öppna',
    rename: 'Döp om',
    delete: 'Radera',
    details: 'Detaljer',
    share: 'Dela med sig',
    move: 'Flytta',
    restore: 'Återställ',
    uploadFile: 'Ladda upp fil',
    createFolder: 'Skapa mapp',
    permanentDelete: 'Permanent radering',
    unlinkShare: 'Avlägsna',
  },
  dialog: {
    common: {
      folder: 'mapp',
      file: 'fil',
      items: 'objekt',
      selectedItems: 'valda objekt (er)',
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nej, avbryt'
    },
    rename: {
      title: 'Döp om',
      message: 'Ange ett nytt {type} namn',
      confirmButtonText: 'Döp om',
      cancelButtonText: 'Annullera',
      validation: 'Ogiltigt {type} namn',
      successMessage: '{fileTypeAndName} byts framgångsrikt till {newName}',
      errorMessage: 'Det gick inte att byta namn, försök efter en tid'
    },
    share: {
      sharedWith: 'Delad med',
      shareResponse: {
        success: '{fileTypeAndName} delade framgångsrikt',
        error: 'Det gick inte att dela {fileTypeAndName}, kan bara delas med användare under samma organisation',
        wrongEmail: 'Den angivna e -postadressen är ogiltig eller oregistrerad',
        alreadyShared: '{fileTypeAndName} redan delat med den här användaren',
      },
      unShareResponse: {
        success: '{fileTypeAndName} avdelad framgångsrikt',
        error: 'detGickInteAttAvskaffa {fileTypeAndName},FörsökIgenSenare',
      },
      unSharePrompt: {
        title: 'Unshare access',
        message: 'Är du säker på att du vill återkalla åtkomst för \'{email}\'?',
      },
      placeholder: 'Ange e -postadress för att dela',
      unShare: 'Skvaller',
      notifyUser: 'Meddela användaren'
    },
    move: {
      title: 'Flytta {type}',
      heading: 'Välj en målmapp för \'{name}\'',
      filterPlaceHolder: 'Filtrera från listan',
      foldersNotFound: 'Kunde inte hitta mappar',
      cancelButtonText: 'Annullera',
      moveButton: 'Flytta',
      moveAlert: {
        message: 'Välj en mapp',
        title: 'Flytta till',
        ok: 'gillande',
      },
      successMessage: '{type} \'{target}\' har framgångsrikt flyttats till folder \'{dest}\'',
      errorMessage: 'Det gick inte att flytta {type} \'{target}\'',
      sameFolderWarning: 'Redan i samma mapp',
    },
    delete: {
      title: 'Radera',
      message: 'Vill du radera den här {fileTypeAndName}?',
      successMessage: 'Raderade framgångsrikt {fileTypeAndName}',
      errorMessage: 'Det gick inte att ta bort, försök efter en tid',
      sharedUserMessage: '{fileTypeAndName} kommer också att raderas från alla delade användare.'
    },
    create: {
      fileUploadSuccessMessage: 'Filen {name} har framgångsrikt laddats upp.',
      fileUploadSpecificFailedMessage: 'Ladda upp misslyckades, {errorMessage}',
      fileUploadGenericFailedMessage: 'Det gick inte att ladda upp {name}.Vänligen försök igen senare.',
      folderCreatedMessage: 'Carpeta creada con éxito',
      fileMaxSizeError: 'Den valda filen får inte vara större än {max} MB.'
    },
    restore: {
      common: {
        errorMessage: 'Det gick inte att återställa, försök efter en tid',
      },
      single: {
        title: 'Återställ',
        message: 'Vill du återställa den här {objectTypeAndName}?',
        checkingRestorePath:'Kontrollera för tidigare plats',
        restorePathExist: 'Objektet kommer att återställas till sin ursprungliga plats',
        restorePathNotExist: 'Den ursprungliga platsen finns inte, objektet återställs till rotmappen',
        sharedUserMessage: 'De delade användarna kommer också att få tillgång till denna artikel.',
        successMessage: '{objectTypeAndName} återställs framgångsrikt',
      },
      multiple: {
        title: 'Återställ valda objekt (er)',
        message: 'Vill du återställa de valda objekten?',
        restoreTip: 'The selected item(s) will be restored to their original location, If the original location no longer exists or they will be restored to the root folder.',
        sharedUserMessage: 'De delade användarna kommer också att få tillgång till artikeln.',
        successMessageMultiple: 'The selected item(s) are successfully restored',
      }
    },
  },
  uploadPage: {
    dragAndDropToUploadContent: 'Dra och släpp för att ladda upp innehåll!',
    exitConfirmation: 'Om du uppdaterar eller lämnar den här sidan avbryts de aktuella filöverföringarna. Är du säker på att du vill avsluta den här sidan?',
    uploadLimit: 'Maximal uppladdningsfilstorlek: {size}',
    dropFileHere: 'Släpp filen här eller',
    clickToSelect: 'Klicka för att välja',
    uploadProgress: 'Uppladdning pågår',
    uploadCompleted: 'File laddas upp framgångsrikt',
    uploadFailed: 'Uppladdning misslyckades, försök efter en tid',
    uploading: 'Uppladdning',
    completed: 'Avslutad',
    failed: 'Misslyckad',
    uploadPaused:'Ladda upp paus'
  },
  SharedPage: {
    title: 'Delad lista'
  },
  common: {
    noActionsFound: 'Inga åtgärder finns tillgängliga',
    logoutMessage: 'No has iniciado sesión en ActaPublica o tu sesión ha expirado. Vuelva a iniciar sesión para continuar.',
    settingsChanged: 'Vi har nyligen gjort några ändringar i din åtkomst till tjänsten. Du har blivit utloggad. Vi ber dig att logga in igen så att dessa ändringar träder i kraft omedelbart.',
    api_request_failed: 'MyLibrary -servern upplever vissa problem.Vänligen försök igen senare.',
    no_access: 'Du har inte tillgång till denna sektion.',
    access_required: 'MyLibrary åtkomst krävs.',
    request_access: 'Begär åtkomst',
    network_unavailable: 'Du är offline, kontrollera din nätverksanslutning',
    httpError: {
      500: 'Internt serverfel: MyLibrary stötte på ett oväntat tillstånd som hindrade det från att uppfylla begäran',
      501: 'Ej implementerad: MyLibrary Server stöder inte den funktionalitet som krävs för att uppfylla begäran',
      502: 'Bad Gateway: Det går inte att nå MyLibrary Server',
      503: 'Service är inte tillgänglig: MyLibrary Service kan för närvarande inte hantera begäran på grund av en tillfällig överbelastning eller underhåll av servern',
      504: 'Gateway Timeout: MyLibrary Server, fick inte ett snabbt svar från uppströmstjänsten',
      400: 'Dålig begäran: Servern kan inte behandla begäran på grund av ett klientfel',
      404: 'Inte hittad: servern kan inte hitta den begärda filen/mappen',
      408: 'Begär Timeout: MyLibrary Server tidsinställda och väntar på begäran',
      429: 'För många förfrågningar: Användaren har skickat för många förfrågningar på en viss tid',
    }
  },
  labels: {
    cancel: 'Annullera',
    create: 'Skapa',
    share: 'Dela med sig',
    open: 'Öppna',
    rename: 'Döp om',
    delete: 'Radera',
    details: 'Detaljer',
    move: 'Flytta',
    email: 'E-post',
    choose_file: 'Välj en fil',
    new_file: 'Ny fil',
    new_folder: 'Ny mapp',
    create_new: 'Skapa Ny',
    file: 'Fil',
    folder: 'Mapp',
    back: 'Tillbaka',
    folders: 'Mappar',
    files: 'Filer'
  },
  titles: {
    share_dialog: 'Dela med människor',
    no_search_result: 'Inga resultat funna'
  },
  formValidation: {
    incorrect_email: 'Ange en giltig e-postadress',
    enter_name: 'Ange ett namn',
    enter_valid_name: 'Ange giltigt namn'
  },
  folderPicker:{
    input:{
      placeholder:'Mappen inte valt',
      button_text:'Välj en mapp',
    },
    select_folder: 'Välj mappen',
    placeholder: 'Filtermappar',
    notFound: 'Inga mappar hittades',
    button:{
      select:'Välj',
      cancel:'Annullera',
    }
  }
};
