var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "footer",
    { staticClass: "footerbar", class: { "on-upload": _vm.showPanel } },
    [
      _vm.showPanel
        ? _c(
            "el-card",
            {
              staticClass: "upload-box",
              attrs: { shadow: "hover", "body-style": _vm.cardBodyStyleProp },
            },
            [
              _c(
                "div",
                {
                  staticClass: "upload-layout",
                  class: {
                    "upload-layout-jfs":
                      _vm.activeStatus ===
                      _vm.enumUploadUIStatus.UPLOAD_COMPLETE,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "column-item col-progress" },
                    [
                      _vm.activeStatus ===
                      _vm.enumUploadUIStatus.UPLOAD_PROGRESS
                        ? _c("el-progress", {
                            staticClass: "spinner",
                            class: {
                              process: _vm.progress === _vm.PROGRESS_MAXIMUM,
                            },
                            attrs: { type: "circle", percentage: _vm.progress },
                          })
                        : _c("svg-icon", {
                            staticClass: "icon-router",
                            attrs: { index: "/upload", name: "cloud_upload" },
                          }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "column-item col-label" }, [
                    _vm.activeStatus === _vm.enumUploadUIStatus.UPLOAD_PROGRESS
                      ? _c("span", { staticClass: "lable-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("uploadPage.uploading")) +
                              " " +
                              _vm._s(_vm.fileName) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.activeStatus === _vm.enumUploadUIStatus.UPLOAD_PAUSE
                      ? _c("span", { staticClass: "lable-text" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("uploadPage.uploadPaused"))
                          ),
                        ])
                      : _vm._e(),
                    _vm.activeStatus === _vm.enumUploadUIStatus.UPLOAD_COMPLETE
                      ? _c("span", { staticClass: "lable-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("route.upload")) +
                              " " +
                              _vm._s(_vm.$t("uploadPage.completed")) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.activeStatus !== _vm.enumUploadUIStatus.UPLOAD_COMPLETE
                    ? _c(
                        "div",
                        { staticClass: "column-item col-action" },
                        [
                          _vm.activeStatus ===
                            _vm.enumUploadUIStatus.UPLOAD_PROGRESS &&
                          _vm.progress < _vm.PROGRESS_MAXIMUM
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    "popper-class": "capitalize",
                                    effect: "dark",
                                    content: _vm.$t("pause"),
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "warning",
                                      icon: "el-icon-video-pause",
                                      size: "mini",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.pause()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.activeStatus ===
                          _vm.enumUploadUIStatus.UPLOAD_PAUSE
                            ? _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    "popper-class": "capitalize",
                                    effect: "dark",
                                    content: _vm.$t("resume"),
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "success",
                                      icon: "el-icon-video-play",
                                      size: "mini",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resume()
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeStatus === _vm.enumUploadUIStatus.UPLOAD_PROGRESS &&
                  _vm.progress < _vm.PROGRESS_MAXIMUM
                    ? _c(
                        "div",
                        { staticClass: "column-item col-action" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                "popper-class": "capitalize",
                                effect: "dark",
                                content: _vm.$t("labels.cancel"),
                                placement: "top-start",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-close",
                                  size: "mini",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancel()
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }